import clsx from "clsx";
import "moment/locale/pt";
import { useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useIntl } from "react-intl";
import { connect } from "react-redux";
// @material-ui/core components
import Box from "@material-ui/core/Box";
import FormGroup from "@material-ui/core/FormGroup";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Tooltip from "@material-ui/core/Tooltip";
import { makeStyles, useTheme } from "@material-ui/core/styles";
// @material-ui/icons components
import ErrorIcon from "@material-ui/icons/Error";
//import custom components
import FormMultipleSelect from "components/FormFields/FormMultipleSelect";
import FormSelect from "components/FormFields/FormSelect";

import FormInput from "components/FormFields/FormInput";
import { getDataSetByNameAction } from "redux/actions/datasetsAction";
import { getUserInfoAction } from "redux/actions/usersAction";
import { errorMessages, labels, messages } from "resources/resources";
import { DATASET } from "utils/consts";
import { getDatasetStateByName } from "utils/datasets";

import componentStylesGeneric from "assets/theme/views/admin/generic.js";

const useStylesGeneric = makeStyles(componentStylesGeneric);

function IndividualUserCompanyData(props) {
  const classes = useStylesGeneric();
  const theme = useTheme();
  const intl = useIntl();
  const { control } = useFormContext();

  const handleGetDataSetByName = (datasetName) => {
    if (props.dataSetState[datasetName] === undefined || props.dataSetState[datasetName]?.length === 0) {
      props.getDataSet(datasetName);
    }
  };

  useEffect(() => {
    handleGetDataSetByName(DATASET.GENDER);
    handleGetDataSetByName(DATASET.MARITAL_STATUS);
    handleGetDataSetByName(DATASET.NATIONALITIES);
    handleGetDataSetByName(DATASET.ACADEMIC_LEVEL);
    handleGetDataSetByName(DATASET.ACADEMIC_DEGREE);
    handleGetDataSetByName(DATASET.PROFESSIONAL_TRAINNING);
    handleGetDataSetByName(DATASET.ENTREPRENEURSHIP_TRAINNING);
    handleGetDataSetByName(DATASET.SMALL_BUSINESS_MGMT_TRAINNING);
    handleGetDataSetByName(DATASET.PROFESSIONAL_CATEGORY);
    handleGetDataSetByName(DATASET.LANGUAGES);
    handleGetDataSetByName(DATASET.OCCUPATIONAL_CATEGORIES);
    handleGetDataSetByName(DATASET.PROVINCES);    
    handleGetDataSetByName(DATASET.EMPLOYMENT_CENTERS);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Nivel Académico</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="academicLevel"
              selectOptions={
                props.dataSetState[DATASET.ACADEMIC_LEVEL] ? props.dataSetState[DATASET.ACADEMIC_LEVEL] : []
              }
              loadChildrenDataSet={props.getDataSet}
              childrenDataSet={DATASET.ACADEMIC_DEGREE}
              childrenSelect="degree"
              setValue={props.setValue}
              getValues={props.getValues}
              required={props.isEditable}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["academicLevel"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["academicLevel"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Curso (opcional)</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="degree"
              selectOptions={
                props.dataSetState[DATASET.ACADEMIC_DEGREE] ? props.dataSetState[DATASET.ACADEMIC_DEGREE] : []
              }
              getValues={props.getValues}
              required={false}
              errors={props.errors}
              classes={classes}
            />
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Categoria Profissional (opcional)</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="professionalCategory"
              selectOptions={
                props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                  ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                  : []
              }
              setValue={props.setValue}
              getValues={props.getValues}
              classes={classes}
            />
            {props.errors["professionalCategory"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["professionalCategory"].message}
              </FormHelperText>
            )}
          </FormGroup>{" "}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Formação profissional</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="professionalTraining"
              selectOptions={
                props.dataSetState[DATASET.PROFESSIONAL_TRAINNING]
                  ? props.dataSetState[DATASET.PROFESSIONAL_TRAINNING]
                  : []
              }
              initialLabel="Seleccione opções aplicáveis"
              getValues={props.getValues}
              limitTags={1}
              required={true}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["professionalTraining"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["professionalTraining"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Formação de empreendedorismo</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="entrepreneurshipTraining"
              selectOptions={
                props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING]
                  ? props.dataSetState[DATASET.ENTREPRENEURSHIP_TRAINNING]
                  : []
              }
              initialLabel="Seleccione opções aplicáveis"
              getValues={props.getValues}
              required={true}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              limitTags={1}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["entrepreneurshipTraining"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["entrepreneurshipTraining"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>Formação de gestão pequenos negócios</FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="smallBusinessMgmtTraining"
              selectOptions={
                props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]
                  ? props.dataSetState[DATASET.SMALL_BUSINESS_MGMT_TRAINNING]
                  : []
              }
              initialLabel="Seleccione opções aplicáveis"
              getValues={props.getValues}
              limitTags={1}
              required={true}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["smallBusinessMgmtTraining"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["smallBusinessMgmtTraining"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_StudiedLanguages)}
            </FormLabel>
            <FormMultipleSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="studiedLanguages"
              selectOptions={getDatasetStateByName(props, DATASET.LANGUAGES)}
              initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
              getValues={props.getValues}
              limitTags={1}
              required={false}
              errors={props.errors}
              classes={classes}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_OccupationalCategory)}
            </FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="occupationalCategory"
              selectOptions={getDatasetStateByName(props, DATASET.OCCUPATIONAL_CATEGORIES)}
              getValues={props.getValues}
              setValue={props.setValue}
              required={false}
              errors={props.errors}
              classes={classes}
            />
          </FormGroup>
        </Grid>

        <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId)}
              <Tooltip
                title={intl.formatMessage(
                  labels.Label_UserProfile_UserSingular_ProfessionalData_IntendedWorkplaceProvinceId_Details
                )}
              >
                <ErrorIcon className={classes.tooltipInfoIcon} />
              </Tooltip>
            </FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="intendedWorkplaceProvince"
              selectOptions={getDatasetStateByName(props, DATASET.PROVINCES)}
              getValues={props.getValues}
              setValue={props.setValue}
              required={false}
              errors={props.errors}
              classes={classes}
            />
          </FormGroup>
        </Grid>
        
      </Grid>
      <Grid container>
      <Grid item xs={12} md={4}>
          <FormGroup>
            <FormLabel className={classes.boxNameCategoryForm}>
              {intl.formatMessage(labels.Label_Opportunity_OpportunityData_EmploymentCenter)}
            </FormLabel>
            <FormSelect
              disabled={!props.isEditable}
              control={control}
              fieldName="employmentCenter"
              selectOptions={getDatasetStateByName(props, DATASET.EMPLOYMENT_CENTERS)}
              getValues={props.getValues}
              setValue={props.setValue}
              required={false}
              errors={props.errors}
              classes={classes}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_MainProfessionalExperience)}
            </FormLabel>
            <FormMultipleSelect
              control={control}
              disabled={!props.isEditable}
              fieldName="professionalExperience"
              selectOptions={
                props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                  ? props.dataSetState[DATASET.PROFESSIONAL_CATEGORY]
                  : []
              }
              initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
              getValues={props.getValues}
              limitTags={1}
              required={true}
              requiredMessage={intl.formatMessage(errorMessages.ErrorMessage_FieldRequired)}
              errors={props.errors}
              classes={classes}
            />
            {props.errors["professionalExperience"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["professionalExperience"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage(
                labels.Label_UserProfile_UserSingular_ProfessionalData_YearsOfMainProfessionalExperience
              )}
            </FormLabel>
            <Controller
              name="experience"
              control={control}
              component={Box}
              defaultValue=""
              rules={{
                required: {
                  value: true,
                  message: intl.formatMessage(errorMessages.ErrorMessage_FieldRequired),
                },
                validate: {
                  minZero: (value) => {
                    if (value < 0) {
                      return intl.formatMessage(messages.Message_Generic_ValueGreaterThanOrEqualToZeroField);
                    }
                  },
                },
              }}
              render={({ field }) => (
                <OutlinedInput
                  disabled={!props.isEditable}
                  {...field}
                  fullWidth
                  type="number"
                  classes={{
                    notchedOutline: clsx({
                      [classes.borderWarning]: props.errors["experience"] !== undefined,
                    }),
                  }}
                />
              )}
            />
            {props.errors["experience"] !== undefined && (
              <FormHelperText component={Box} color={theme.palette.warning.main + "!important"}>
                {props.errors["experience"].message}
              </FormHelperText>
            )}
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormGroup>
            <FormLabel>
              {intl.formatMessage(
                labels.Label_UserProfile_UserSingular_ProfessionalData_SecondaryProfessionalExperience
              )}
            </FormLabel>
            <FormMultipleSelect
              control={control}
              fieldName="secondaryProfessionalExperience"
              disabled={!props.isEditable}
              selectOptions={getDatasetStateByName(props, DATASET.PROFESSIONAL_CATEGORY)}
              initialLabel={intl.formatMessage(labels.Label_SelectMultipleOptions)}
              getValues={props.getValues}
              limitTags={2}
              required={false}
              errors={props.errors}
              classes={classes}
            />
          </FormGroup>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormInput
            name="secondaryExperience"
            label={intl.formatMessage(
              labels.Label_UserProfile_UserSingular_ProfessionalData_YearsOfSecondaryProfessionalExperience
            )}
            disabled={!props.isEditable}
            numberType={true}
            control={control}
            errors={props.errors}
            rules={{
              validate: {
                minValue: (value) => {
                  if (value < 0)
                    return intl.formatMessage(messages.Message_Generic_ValueGreaterThanOrEqualToZeroField);
                  return true;
                },
              },
            }}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            name="professionalQualification"
            disabled={!props.isEditable}
            label={intl.formatMessage(labels.Label_UserProfile_UserSingular_ProfessionalData_ProfessionalQualification)}
            multiline={true}
            rows={3}
            control={control}
            errors={props.errors}
          />
        </Grid>

        <Grid item xs={12}>
          <FormInput
            name="otherProfessionalExperience"
            disabled={!props.isEditable}
            label={intl.formatMessage(
              labels.Label_UserProfile_UserSingular_ProfessionalData_OtherProfessionalExperience
            )}
            multiline={true}
            rows={3}
            control={control}
            errors={props.errors}
          />
        </Grid>
      </Grid>
    </>
  );
}

const mapStateToProps = (state) => ({ ...state });

const mapDispatchToProps = (dispatch) => ({
  getDataSet: (name, parentKeyValue) => dispatch(getDataSetByNameAction(name, parentKeyValue)),
  getUserInfo: (id) => dispatch(getUserInfoAction(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndividualUserCompanyData);
